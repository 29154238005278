import React from "react"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  return (
    <>
      {/*<Helmet htmlAttributes={{ lang: "zh-CN" }}>*/}
      <Helmet>
        <meta name="keywords" content="ayabala 啊呀吧啦" />
        <meta name="description" content="FantasyNJ的个人主页" />
        <title>ayabala首页</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <script src="https://at.alicdn.com/t/font_2022481_rchs89m6zm.js"></script>
      </Helmet>
      {children}
    </>
  )
}

export default Layout
